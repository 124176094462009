
@font-face { font-family: "Favorit-Medium"; src: local("abcfavorit-medium"), url("fonts/abcfavorit.medium.woff") format("woff"); }
@font-face { font-family: "Favorit-Extended-Bold"; src: url("fonts/abcfavorit.extended.bold.woff2") format("woff2"); font-weight: bold; }
@font-face { font-family: "Favorit-Lining-Medium"; src: url("fonts/abcfavorit.lining.medium.woff2") format("woff2"); font-style: underline; }
@font-face { font-family: "Adelle-Sans"; src: local("adelle-sans"), url("fonts/adelle.sans.otf") format("opentype"); }
@font-face { font-family: "Adelle-Sans-Italic"; src: local("adelle-sans-italic"), url("fonts/adelle.sans.italic.otf") format("opentype"); font-style: italic; }
@font-face { font-family: "Adelle-Sans-Bold"; src: local("adelle-sans-bold"), url("fonts/adelle.sans.bold.otf") format("opentype"); font-weight: bold; }
@font-face { font-family: "Adelle-Sans-Bold-Italic"; src: local("adelle-sans-bold-italic"), url("fonts/adelle.sans.bold.italic.otf") format("opentype"); font-style: italic; font-weight: bold; }

.black { color: #000; }
.red { color: #e54038; }
.floodRed { background: #e54038; color: white; }
.floodYellow { background: #eff26d; color: #e54038; }

* { font-family: "Adelle-Sans",sans-serif; letter-spacing: -0.01em; }
body { padding-top: 40px; background-color: #F8F6F1; margin: 0; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; }

.thirdwidth { width: 33%; }
.thirdwidth.centered { margin-left: 33%; }
.halfwidth { width: 50%; }
.midwidth { width: 67%; }
.halfover { width: 50%; padding-left: 50%; }
.threequarterwidth { width: 75%; }
.autocenter { margin-left: auto !important; margin-right: auto !important; }
.padtop_small { padding-top: 14px; }
.padtop_medium { padding-top: 28px;}
.padtop_big { padding-top: 84px; }
.padtop_bigger { padding-top: 180px; }
.padtop_jumbo { padding-top: 220px; }
.padAround { padding: 28px; }
.padBottom_small { padding-bottom: 14px; }
.padBottom_medium { padding-bottom: 28px; }
.padBottom_jumbo { padding-bottom: 84px; }

.twoColumns { position: relative; display: flex; }
.twoColumns .column { width: 47%; }
.twoColumns .column:nth-of-type(2){ padding-left: 6%; }
.twoColumns.split { justify-content: space-between;}
.twoColumns.split .column { padding-left: initial; }
.twoColumns.split::after { position: absolute; content: ""; top: 25%; bottom: -50px; left: 50%; width: 2px; background-color: #e54038; }
.splitperpindicular { position: relative; padding-top: 50px;}
.splitperpindicular::before { content: ""; position: absolute; top: 50px; left: 35%; right: 35%; height: 2px; background: #e54038; }

h1, .h1, h1 .span { font-family: "Favorit-Medium",sans-serif; font-weight: 300; color: #e54038; text-decoration: none; font-size: 80px; line-height: 1; letter-spacing: -.02em; }
h2, a.h2 { font-family: "Favorit-Medium",sans-serif; font-weight: 300; color: #e54038; text-decoration: none; font-size: 41px; line-height: 42px; }
h3, a.h3 { font-family: "Favorit-Medium"; color: #e54038; font-size: 28px; line-height: 34px; font-weight: 300; letter-spacing: -.01em; }
h4, a.h4 { font-family: "Favorit-Medium",sans-serif; font-weight: 300; color: #e54038; text-decoration: none; font-size: 32px; line-height: 1.2; }
h5 { font-family: "Favorit-Medium",sans-serif; color: #e54038; font-size: 22px; font-weight: 300; }
h6 { font-family: "Favorit-Medium",sans-serif; color: #e54038; font-size: 17px; font-weight: 300; }

a.h2, a.h3, a.h4 { text-decoration: none;}

.twoxleading { line-height: 2.125; }
ul.twoxleading li { padding-bottom: 1.5rem; }
.lined { font-family: "Favorit-Lining-Medium"; }
.title { font-size: 12px; font-family: "Favorit-Extended-Bold", sans-serif; font-weight: 300; line-height: 20px; text-transform: uppercase; }
.smalltitle { font-size: 10px; font-family: "Favorit-Extended-Bold", sans-serif; font-weight: 300; line-height: 20px; text-transform: uppercase; }
.smalltitle.medium { font-family: "Favorit-Medium",sans-serif; }
.caption { font-size: 14px; }
.flushRight { text-align: right; }
.forceInline { display: flex; flex-direction: row; align-items: center;}
.half { width: 50%; }
.tightbottom { margin-bottom: 0; }
.tighttop { margin-top: 0; }
.italic { font-style: italic; font-family: "Adelle-Sans-Italic" }
.bold { font-weight: 600; }
.padright { padding-right: 1rem; }
.inline { margin: .5rem 0; }
.highlight { background-color: rgba(239, 242, 109, 1); }
ol.favNums { line-height: 2.5; max-width: 75%; }
ol.favNums li { margin-bottom: 1rem; }
ol.favNums li::marker { font-size: 20px; font-family: "Favorit-Medium",sans-serif; font-weight: 300; }
ol.favNums.red li::marker { color: #e54038; }

section.imageBackgroundLeft { position: relative; }
section.imageBackgroundLeft .rightHalf { position: relative; padding-left: 50%; padding-top: 6rem; z-index: 2}
section.imageBackgroundLeft .column { z-index: 2; }
section.imageBackgroundLeft .imgWrapper { position: absolute; top: 0; left: 0; bottom: 0; width: 66%; z-index: 1; }
section.imageBackgroundLeft .imgWrapper img { height: 100%; }

section.aboutUsHero { position: relative; padding: 0; margin-top: -60px; background-position: center right; background-size: contain; background-repeat: no-repeat}
section.aboutUsHero div { position: relative; padding: 12rem 40% 6rem 42px; z-index: 2; }
section.aboutUsHero div h1 { width: 57%; }

@media only screen and (max-width: 1325px) {
    section.aboutUsHero div { padding-right: 55%; }
    section.aboutUsHero div h1 { width: 66%; }
}

@media only screen and (max-width: 1000px) {
    section.aboutUsHero { background-size: cover; }
    section.aboutUsHero div { padding-right: 33%; }
    section.aboutUsHero div h1 { width: 66%; }
    section.aboutUsHero div h1, section.aboutUsHero div h4 { position: relative; z-index: 2; }
}


.calendly-inline-widget { padding-top: 40px; }
.calendlyCTA { position: fixed; top: 0; left: 0; right: 0; height: 40px; background: #302a2a; text-align: right; padding: 1px 35px 0; z-index: 20; }
.calendlyCTA span { color: #eff26d; font-family: "Adelle-Sans"; font-size: 12px; line-height: 36px; letter-spacing: -0.01em; }
.calendlyCTA a.button { margin: 7px 4px; padding: 7px 10px; line-height: 1; font-family: "Favorit-Extended-Bold", sans-serif; text-transform: uppercase; font-size: 9px; margin-left: 21px; }
header { position: fixed; top: 40px; left: 0; right: 0; height: 69px; padding: 0 21px; z-index: 20; background-color: rgba(248, 246, 241,0); transition: all .3s ease-out; }
header.opaque { background: rgba(248, 246, 241, 100); }

header.floodRed.opaque { background: #e54038; }
header.floodRed ul li a { color: white !important; }
header.floodRed ul li a.active { border-bottom: 1px solid white; }
header.floodRed svg * { fill: white; }

header svg { position: absolute; top: 0; left: 42px; max-width: 100px; }
header ul { position: absolute; top: 7px; right: 49px; list-style-type: none; }
header ul li { padding-left: 40px; display: inline-block; font-size: 12px; font-family: "Favorit-Extended-Bold", sans-serif; font-weight: 300; line-height: 20px; text-transform: uppercase; }
header ul li a { color: #e54038; text-decoration: none; font-size: 12px; font-family: "Favorit-Extended-Bold", sans-serif; font-weight: 300; line-height: 20px; text-transform: uppercase; }
header ul li a.active { border-bottom: 1px solid #e54038; }

footer { padding: 42px; color: #e54038; }
footer .split { position: relative; }
footer .split .col75 { width: 75%; }
footer .split .col75 h2 { margin-bottom: 28px; }
footer .split .acorn { position: absolute; right: -42px; top: 0; }
footer .footerLinks { margin-top: 100px; display: flex; width: 100%; }
footer .footerLinks .contact { width: 20%; font-size: 14px; line-height: 1.4; }
footer .footerLinks .contact div .twoColumns a img { margin: 21px 21px 7px 0; }
footer .footerLinks .links { width: 15%; padding-left: 5%; }
footer .footerLinks .links a { display: block; margin-bottom: 21px; }
footer .footerLinks form { width: 30%; padding-left: 10% }
footer .footerLinks form input { background: none; border: none; border-bottom: 1px solid #e54038; width: 100%; margin: 14px 0 21px 0; padding: 7px 14px; font-size: 16px; }
footer .footerLinks form .button { margin-top: 14px; }
footer .finale { margin-top: 72px; display: flex; justify-content: space-between; align-items: center; }
footer .finale .smalltitle a { padding-left: 14px; }
footer .finale .oneftp { display: flex; align-items: center; }
footer .finale .oneftp img { max-height: 32px; margin-left: 7px; }

a.red { text-decoration: none; color: #e54038; }
a.button, button { display: inline-block; font-size: 12px; box-shadow: none; font-family: "Favorit-Extended-Bold", sans-serif; padding-top: 10px; padding-left: 20px; touch-action: manipulation; border-radius: 30px; padding-right: 20px; padding-bottom: 8px; text-transform: uppercase; text-decoration: none; transition: 0.3s all; }
a.button.yellow, button.yellow, input.yellow { color: #e54038; border: solid 1px #e54038; background-color: #eff26d; }
a.button.yellow:hover, button.yellow:hover, input.yellow:hover { color: #eff26d; background-color: #e54038; border-color: #eff26d; }
a.button.yellow.secondary { background: none; border-color: #eff26d; color: #eff26d; }
a.button.yellow.secondary:hover { border-color: #fff; color: #fff; }
.floodBlack a.button.yellow.secondary:hover { color: #302A29; background-color: #eff26d; border-color: #eff26d; }
a.button.red { border: 1px solid white; }

section { padding: 42px 42px 72px 42px; }
section.tighterbottom { padding-bottom: 42px; }
section.compensatePadding { padding: 14px 42px 72px; }
section.homeTwoColumns { display: flex; }
section.homeTwoColumns img { max-width: 50%; align-self: center; }
section.homeTwoColumns img.pullRight { max-width: 40%; margin-right: -42px; padding-left: 72px; }
section.homeTwoColumns div.maxhalf { width: 50%; }

section.homeTwoColumns.noPadLeft { padding-left: 0; }
section.homeTwoColumns.noPadLeft img { max-width: initial; }


section.processCenter { text-align: center; padding-top: 0; }
section.processCenter h1 { margin-top: 0; }
section.processCenter img { max-width: 80%; }

img.pullLeft.spanthreequarter { max-width: 75%; margin-left: -42px; }

section.floodRed { background-color: #e54038; color: white; }
section.floodRed * { color: white; }

section.floodBlack { background-color: #302A29; color: #eff26d; }
section.floodBlack *:not(.yellow) { color: #eff26d; }

section.floodBlack h4 { margin-top: 0; }

section.floodYellow { background-color: #eff26d; color: #e54038; }
section.floodYellow * { color: #e54038; }

section .twoColumns.borderTop { border-top: 1px solid #e54038; }
section .twoColumns.borderTop * { margin-top: 0 !important; padding-top: 14px; }

.twoColumns.getInTouch { margin-top: 7rem; }
.twoColumns.getInTouch div:nth-of-type(1) { width: 65%; }
.twoColumns.getInTouch div:nth-of-type(2) { width: 35%; }
.twoColumns.getInTouch div p button, .twoColumns.getInTouch div p a { margin-left: 1rem; }
.twoColumns.getInTouch * { margin-bottom: 0; }

.centered { position: relative; text-align: center; }
section.centered img.backgroundImage { position: absolute; top: 21px; width: 30%; left: 35%; right: 35%; z-index: 1; }
section.centered .overBackground { position: relative; z-index: 4; padding-top: 72px; }
.discoverSubhead { width: 550px; margin-left: auto; margin-right: auto; }

img.caseStudyHero { background-color: white; border: 1px solid black; align-self: flex-start; max-width: 100%; }
.casePreview { display: block; padding: 1.5rem 0; cursor: pointer; }
.casePreview h2 { margin-bottom: 7px; }
a.casePreview { text-decoration: none; }
a.casePreview:hover h2 { text-decoration: underline; }
a.casePreview:hover img.caseStudyHero { border-color: #e54038; }
.casePreview h6, .caseDetailHeader h6 { margin: 1.2rem 0; display: flex; }
.casePreview h6 .title.bordered, .caseDetailHeader h6 .title.bordered { border: 1px solid #e54038; padding: 0 6px; margin-right: .7rem; align-self: center; font-size: 10px; line-height: 1.6; padding-top: 1px; }
.casePreview h6 .title.bordered.marginleft, .caseDetailHeader h6 .title.bordered.marginleft { margin-left: 2rem; }

.threeColumns { display: flex; justify-content: space-between; }
.threeColumns .column { display: flex; flex-wrap: wrap; max-width: 27%; margin: 28px 14px 28px 0; }
.threeColumns.stacked .column { flex-direction: column; }
.threeColumns.stacked .column .title { margin-bottom: 2rem; }
.threeColumns.ruled .column { border-top: 1px solid white; border-bottom: 1px solid white; padding: 14px 0; }
.threeColumns.ruled .column .title { width: 50%; }
.threeColumns.ruled .column .body { width: 50%; }
.threeColumns .column .action { width: 100%; text-align: right; align-self: flex-end; }
.threeColumns .column .action a.button { display: inline-block; margin-top: 14px; }
.threeColumns .column .action a.button:hover { background: #fff; color: #e54038; }

.fourColumns { display: flex; justify-content: space-between;}
.fourColumns .column { max-width: 23%; }
.fourColumns.iconHeader .column svg { max-width: 36px; max-height: 36px; }
.fourColumns.iconHeader .column svg * { fill: white; }

h2 a.linedHover { font-family: "Favorit-Medium"; color: #e54038; text-decoration: none; }
h2 a.linedHover:hover { font-family: "Favorit-Lining-Medium"; }

.stackItem { position: relative; max-width: 70%; color: #e54038; padding-left: 62px; }
.stackItem svg { position: absolute; left: 0; top: 24px; max-width: 34px; fill: #e54038; }
.stackItem .itemHeader { padding-top: 28px; }


section.borderTopRed { position: relative; padding-top: 21px; }
section.borderTopRed:before { position: absolute; top: 11px; left: 42px; right: 42px; content: ""; border-top: 1px solid #e54038; }

.cardWrapper {display: flex; flex-direction: row; justify-content: space-between; flex-wrap: wrap }
.cardWrapper .teamCard { width: 23%; min-width: 250px; max-width: 350px; margin-top: 30px }
.cardWrapper .teamCard img { max-width: 100%; }
.cardWrapper .teamCard h4 { margin: 6px 0; }
.cardWrapper .teamCard .title { margin: 2px 0; }
.cardWrapper .blank_div_for_responsive { width: 23%; min-width: 250px; max-width: 350px; height: 0; }

section.processCards { padding-left: 0; padding-right: 0; }
section.processCards .processCard h2 { margin-top: 0; }
section.processCards .processCard p { width: 55%; }

section.processCards .processCardsIntro { padding: 0 55% 0 6rem; }
section.processCards .processCardsIntro .forceInline a.button { margin-left: 1rem; }

section.processCards .processCard.one { margin-top: -9rem; padding: 0 9rem 0 50%; }
section.processCards .processCard.one img { padding-left: 5rem; margin-bottom: -5rem; }

section.processCards .processCard.two { margin-top: -6rem; padding: 0 55% 0 9rem; }
section.processCards .processCard.two img { max-width: 100%; margin-left: -8rem; margin-bottom: -3rem; }

section.processCards .processCard.three { position: relative; top: -12rem; left: 0; right: 0;  }
section.processCards .processCard.three .imgWrapper { text-align: right;  }
section.processCards .processCard.three img { padding-left: 5rem; margin-bottom: -1rem; }
section.processCards .processCard.three h5, section.processCards .processCard.three h2, section.processCards .processCard.three p { padding-left: 50%; padding-right: 6rem; }
section.processCards .processCard.three p { width: 24rem; }


section.contactformWrapper { background-position: center right; background-size: 25rem; background-repeat: no-repeat;  }
form.contactform { display: block; padding-right: 40%; }
form.contactform div.sidebyside { display: flex; justify-content: space-between; }
form.contactform div.sidebyside div.inputWrapper { width: 45%; }
form.contactform div.inputWrapper { padding-bottom: 1rem; }
form.contactform label { display: block; font-family: "Favorit-Extended-Bold", sans-serif; font-size: 12px; text-transform: uppercase; }
form.contactform input:not(.yellow), form.contactform textarea { margin-top: 7px; padding: 14px; width: 100%; border: 1px solid #e54038;  }
form.contactform input.error { border-width: 3px; color: #e54038;  }
form.contactform input.error::placeholder { color: #e54038; }

.blogHeader { padding-bottom: 0; }
.blogHeaderWrapper { position: relative; display: flex; flex-direction: row; align-items: flex-end; border-bottom: 1px solid red; padding-bottom: 2rem; margin-bottom: 1rem;}
.blogHeaderWrapper h1 { padding-right: 2rem; margin-bottom: 0; }
.blogHeaderWrapper h6 { margin-bottom: 1rem;  }

.blogDetailWrapper .centered { padding: 0 25%; margin-bottom: 8rem; }
.blogDetailWrapper .caption { display: block; }
img.blogDetailHeroImage { max-width: 50%; padding: 0 25%; margin-top: -6rem; }
.blogDetailBody { max-width: 40%; padding: 0 30% 6rem; color: #e54038; line-height: 2; margin-top: -6rem; }
.blogDetailBody p img { text-align: center; margin-left: -15%; margin-right: -15%; width: 130%; height: auto; object-fit: contain; }
.blogDetailBody p a { color: #e54038; text-decoration: none; border-bottom: 1px dashed #e54038; font-weight: bold; }
.blogDetailBody h1 { display: none; }

.blogTop .blogChapterOne { display: flex; }
.blogTop .blogChapterOne .blogHeroes { width: calc(100% - 18rem ); }
.blogTop .blogChapterOne .blogSidebar { width: 18rem; }
.blogTop .blogChapterOne .blogSidebar .block { border: 1px solid #e54038; padding: 1.5rem; margin-bottom: 2rem; }
.blogTop .blogChapterOne .blogSidebar .block:last-of-type { margin-bottom: 0; }
.blogTop .blogChapterOne .blogSidebar iframe { margin-top: 2rem; }
.blogTop .blogChapterOne .blogSidebar .block .quote { position: relative; padding-top: 4rem; }
.blogTop .blogChapterOne .blogSidebar .block .quote:before { content: "“"; color: #e54038; font-size: 6rem; position: absolute; top: 0; left: 0; }
.blogTop .blogChapterOne .article { display: block; text-decoration: none; width: calc(100% - 3rem); }
.blogTop .blogChapterOne .article:hover .articleTitle { text-decoration: underline; }
.blogTop .blogChapterOne .article .articleDateTags { padding-top: 1rem;}
.blogTop .blogChapterOne .article .articleTitle { max-width: 60%; margin-top: 0.5rem; }
.blogTop .blogChapterOne .article .articleImage img { width: 100%; }
.blogTop .blogChapterOne .article .articleImage { position: relative; background-size: cover; width: 100%; aspect-ratio: 600 / 318; background-color: #ccc; }

.blogTop .blogChapterTwo { display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between; padding-top: 3rem; }
.blogTop .blogChapterTwo .article { display: block; text-decoration: none; width: 48.5%;  }
.blogTop .blogChapterTwo .article .articleImage img { max-width: 100%; aspect-ratio: 600 / 318; object-fit: cover; }
.blogTop .blogChapterTwo .article .articleTitle { margin-top: 0.5rem; }
.blogTop .blogChapterTwo .article .articleImage { position: relative; background-size: cover; width: 100%; aspect-ratio: 600 / 318; background-color: #ccc; }

.values a.hover { text-decoration: none; }
.values a.hover h2:hover { font-family: "Favorit-Lining-Medium"; }
#insight { background-position-x: right; background-position-y: 20%; background-repeat: no-repeat; padding-right: 46%; }
#diversity { background-position: bottom right; background-repeat: no-repeat; padding-right: 46%; }
#insight p.twoxleading, #diversity p.twoxleading { max-width: 66%; }
#values .threeColumns .column h5, #responsibility .threeColumns .column h5 { margin-top: 0; margin-bottom: 0.5rem; }
#responsibility .padWrapper { max-width: 55%; }

#friendly { background-position: bottom right; background-size: contain; background-repeat: no-repeat; padding-right: 45%; }

#cocktail { background-position: bottom right; background-repeat: no-repeat; background-size: 27%; }

.centeredHero { text-align: center; padding: 0 25% 42px; }
.centeredHero.wider { padding: 0 15% 42px; }
.centeredHero img { max-width: 40%; }
.centeredHero h1 { margin:0; }
.centeredHero h4, .centeredHero p { padding: 0 10%; }

.longform { max-width: 800px; margin: 0 auto; }
.longform * { color: #e54038; line-height: 1.4; }

.caseDetailHeader { position: relative; }
.caseDetailHeader .casetitle { width: 45%; }
.caseDetailHeader .caseimage { position: absolute; z-index: 2; bottom: -204px; right: 6rem; width: 600px; aspect-ratio: 1/1; border: 2px solid #e54038; background-size: cover }

.caseBody { position: relative; padding-bottom: 0; }
.caseBody .ddd { position: relative; width: calc(100% - 6rem - 262px); border-right: 3px solid #e54038; z-index: 1; display: flex; flex-direction: row; flex-wrap: wrap; }
.caseBody .ddd .title { width: 15%; }
.caseBody .ddd .bodyDetail { width: 40%; padding-right: 45%; padding-bottom: 2rem; margin-top: -0.7rem; }
.caseBody .pullout { position: absolute; right: 6rem; width: 558px; border: 2px solid #e54038; padding: 21px; }
.caseBody h3 { margin: 0; padding: 2rem 0; }
.caseBody .caseDiscover { margin-left: 12rem; width: calc(100% - 18rem - 279px); margin-top: 229px; padding-left: 3rem; border-top: 2px solid #e54038; border-left: 2px solid #e54038; border-bottom: 2px solid #e54038; }
.caseBody .caseDiscover .caseContent { margin-top: 9rem; }
.caseBody .caseDesign { position: relative; margin-top: 0; border-right: 2px solid #e54038; margin-left: 3rem; width: calc(100% - 6rem - 279px); }
.caseBody .caseDesign .caseContent { padding: 6rem 6rem 10rem 6rem; }
.caseBody .caseDeploy { margin-top: 0; border-top: 2px solid #e54038; border-left: 2px solid #e54038; margin-left: 3rem; width: calc(100% - 6rem - 279px); }
.caseBody .caseDeploy .caseContent { padding: 9rem 6rem;}
.caseContent { padding: 42px 21px 21px;  }
.caseBody .caseDesign .secondpullout { position: absolute; right: -279px; width: 558px; border: 2px solid #e54038; padding: 21px; }

.ourwho { background-position: 80% 40%; background-size: 25%; background-repeat: no-repeat; }
.gettoknowus { background-position: 10% bottom; background-size: 650px; background-repeat: no-repeat; }

header nav .toggleButton { display: none; }

@media only screen and (max-width: 900px) {
    #root { padding-top: 120px; }

    header nav { display: block; transition: none !important; }
    header nav ul { display: none; }
    header nav .toggleButton { display: inline-block; font-size: 3.6rem; color: #e54038; position: absolute; top: 16px; right: 36px; border: 0; padding: 5px; background: none; cursor: pointer; transition: none !important; }
    header nav.open .toggleButton { top: -34px; }
    header nav.open { position: fixed; top: 100px; left: 0; right: 0; bottom: 0; z-index: 10; background: #F8F6F1; z-index: 30; }
    
    header nav .toggleButton .active { line-height: .1;}
    header nav .toggleButton .inactive { position: relative; width: 22px; height: 12px; display: block; border-top: 3px solid #e54038; border-bottom: 3px solid #e54038; }
    header nav .toggleButton .inactive::after { content: ""; position: absolute; top: calc(50% - 1.6px); left: 0; right: 0; height: 3px; background-color: #e54038;}

    header nav .toggleButton .active { display: none; }
    header nav.open .toggleButton .active { display: block; }
    header nav .toggleButton .inactive { display: block; }
    header nav.open .toggleButton .inactive { display: none; }
    

    header nav.open ul { display: block; position: absolute; top: 10%; left: 20%; right: 20%; list-style-type: none; }
    header ul li { padding-left: 0; text-align: center; display: block; font-size: 12px; font-family: "Favorit-Extended-Bold", sans-serif; font-weight: 300; line-height: 20px; text-transform: uppercase; margin-bottom: 30px;}
    

    section.contactformWrapper { background: none !important; }
    form.contactform { display: block; padding-right: 10%; }
    .twoColumns, .threeColumns { flex-wrap: wrap; flex-direction: column; }
    .twoColumns .column, .threeColumns .column { max-width: 100%; width: 90%; padding-left: 5%; padding-right: 5%; }
    .half, .halfwidth { width: 100%; }
    .fourColumns { display: block; }
    .fourColumns .column { max-width: 100%;}
    .midwidth { width: 100%; }
    .thirdwidth { width: 100%; }
    section.homeTwoColumns div.maxhalf { width: 100%; }
    section.homeTwoColumns { display: block; overflow-y: auto; }
    section.homeTwoColumns div { display: relative; width: 100%; }
    section.homeTwoColumns div img.pullRight { max-width: 70%; margin-right: 0; margin-left: 0;}
    section.homeTwoColumns img.pullRight { float: right; }
    section.homeTwoColumns img { align-self: auto; }
    section.homeTwoColumns div h4.padtop_jumbo { padding-top: 0; }

    .discoverSubhead { width: 100%; }
    
    #insight, #diversity { padding-right: 21px; }
    #insight p.twoxleading, #diversity p.twoxleading { max-width: 100%; }
    #insight, #diversity, #friendly { background-size: 200px; background-position: top right; padding-top: 220px; padding-right: 5%; }
    #insight .title, #diversity .title, #friendly .title { margin-top: -200px; padding-bottom: 140px; }

    section.processCards .processCardsIntro { padding: 0 5%; }
    section.processCards .processCard.one, section.processCards .processCard.two, section.processCards .processCard.three { margin-top: 0; padding: 0 5%; }
    section.processCards .processCard p { width: 100%; }
    section.processCards .processCard.three img { margin-bottom: 0; }
    section.processCards .processCard.three { top: 0; }
    section.processCards .processCard.three h5, section.processCards .processCard.three h2, section.processCards .processCard.three p { padding: 0 5%;}
    
    .threeColumns.lined .column { border-bottom: none; }

    .twoColumns.getInTouch div:nth-of-type(1){ width: 100%; }
    .twoColumns.getInTouch div:nth-of-type(2){ width: 100%; text-align: left; }
    .twoColumns.getInTouch div:nth-of-type(2) p.flushRight { text-align: left; }

    footer .footerLinks { display: block; }
    footer .footerLinks .contact { width: 100%; }
    footer .footerLinks .links { width: 100%; }
    footer .footerLinks form { padding-left: 5%; padding-top: 32px; width: 80%; }


    .caseDetailHeader { position: relative; }
    .caseDetailHeader .casetitle { width: 100%; }
    .caseDetailHeader .caseimage { position: relative; bottom: auto; max-width: 100%; left: 0; aspect-ratio: 1/1; border: 2px solid #e54038; background-size: cover }
    .caseDetailHeader h5 span { display: block; }
    .casePreview h6 .title.bordered.marginleft, .caseDetailHeader h6 .title.bordered.marginleft { margin-left: .8rem; margin-top: 0; }

    .caseBody { position: relative; padding-bottom: 0; }
    .caseBody .ddd { position: relative; width: 100%; display: block; border: none; }
    .caseBody .ddd.padtop_jumbo { padding-top: 7px; }
    .caseBody .ddd .title { width: 100%; margin-top: 1rem; }
    .caseBody .ddd .bodyDetail { width: 100%; padding-right: 0; padding-bottom: 0; margin-top: 0; }
    .caseBody .pullout { position: relative; right: 0; width: calc(100% - 42px); border: 2px solid #e54038; padding: 21px; margin-top: 2rem; }
    .caseBody h3 { margin: 0; padding: 0; }
    .caseBody .caseDiscover { margin-left: 0; width: 100%; margin-top: 0; padding-left: 0; border: none; }
    .caseBody .caseDiscover .caseContent { margin-top: 9rem; }
    .caseBody .caseDesign { position: relative; margin-top: 2rem; border: none; margin-left: 0; width: 100%; }
    .caseBody .caseDesign .caseContent { padding: 0; }
    .caseBody .caseDeploy { margin-top: 2rem; border: none; margin-left: 0; width: 100%; }
    .caseBody .caseDeploy .caseContent { padding: 0;}
    .caseContent { padding: 0;  }
    .caseBody .caseDesign .secondpullout { position: relative; right: 0; left: -42px; width: calc(100% + 37px); border: 2px solid #e54038; padding: 21px; }
    .floodYellow.padtop_jumbo.caseDetailHeader { padding-top: 42px; }

}
